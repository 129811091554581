body {
  width: 100%;
  height: 100vh;
  margin: 0;
  padding: 0;
  background-color: #f7f8fa;
  display: flex;
  justify-content: center;
}

#root {
  display: flex;
  flex-direction: column;
  height: auto;
  width: 100%;
}

button {
  background: none;
  border: none;
  padding: 0;
  margin: 0;
}

*:focus {
  outline: none;
}

h1,
h2,
h3,
h4,
h5,
h6,
span,
p,
button,
a,
div {
  font-family: 'Open Sans', sans-serif;
}
